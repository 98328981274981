// import ripple.css
function doRipple(event, timems = 400) {
    const target = event.currentTarget;

    const span = document.createElement('span');
    const diameter = Math.max(target.clientWidth, target.clientHeight);
    const radius = diameter / 2;

    const rect = target.getBoundingClientRect();
    span.style.width = span.style.height = `${diameter}px`;
    span.style.left = `${event.clientX - (rect.x + radius)}px`;
    span.style.top = `${event.clientY - (rect.y + radius)}px`;

    span.style.animation = `ripple ${timems}ms linear`;

    span.classList.add('ripple');

    target.appendChild(span);

    setTimeout(() => {
        span.remove();
    }, timems);
}

export { doRipple };