import React, { Component } from 'react';
import './Testimonials.scss';
import StartAnchor from './StartAnchor';
import CF from '../libs/commonFunctions';
import { starBlank, starFilled, testimonialDataUrl } from '../libs/constants';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

/************ */
export default class Testimonials extends Component {

    constructor(props) {
        super(props);
        this.state = {
            testimonialData: [],
            reveiwGiveLink: "",
            reviewMoreLink: ""
        };
    }

    async componentDidMount() {
        const config = await CF.fetchConfig();
        const reviewGiveLink = config['review-give-link']
        const reviewMoreLink = config['review-more-link']
        const testimonialData = await CF.fetchJson(testimonialDataUrl)
        this.setState({ testimonialData, reviewGiveLink, reviewMoreLink })
    }

    render() {
        const { testimonialData, reviewGiveLink, reviewMoreLink } = this.state
        const tetimonialElements = testimonialData.slice(0, 5).map(
            (e, i) => <TestimonialBlock key={i} data={e} />
        )
        return (
            <div className="testimonial-component">
                <StartAnchor id={"testimonial"} />
                <h2 className="component-title">Testimonials</h2>
                <div className="testimonial-container">{tetimonialElements}</div>
                <div className='button-container'>
                    <button
                        className="more-button blue-button outline-ripple"
                        onClick={() => CF.openPage(reviewMoreLink)}
                    >More <FontAwesomeIcon icon={faArrowRight} /></button>
                    <button
                        className="review-button dull-blue-button outline-ripple "
                        onClick={() => CF.openPage(reviewGiveLink)}
                    >Give Review <FontAwesomeIcon icon={faArrowRight} /> </button>
                </div>
            </div>
        )
    }
}

class TestimonialBlock extends Component {
    render() {
        return (
            <div className='testimonial-block'>
                <div className='block-head'>
                    <p className='user-name'>{this.props.data.userName}</p>
                    <RiviewStars rating={this.props.data.rating} />
                </div>

                <div className='block-text gray-font'>
                    {this.props.data.text}
                </div>
            </div>
        );
    }
}


function RiviewStars(props) {

    let starArr = [];

    for (let i = 1; i <= 5; i++) {
        starArr.push(
            (Number.parseInt(props.rating) >= i) ?
                <img key={i} src={starFilled} alt='filled-star' className='star checked' /> :
                <img key={i} src={starBlank} alt='blank-star' className='star unchecked' />
        )
    }
    return <div className='stars-group'>
        {starArr}
    </div>
}
