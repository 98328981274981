import { configUrl, imgListUrl, top6ImgListUrl } from "./constants";

/*collaction of common functions to be used in multiple files
 */
class CommonFunc {

    /** Get Multiline text for given array with \n
     * 'ABC\nDEF'
     * ['ABC',<br/>,'DEF']
     */
    static get_MultiLineText(str) {
        let strArr = str.split('\n');
        let len = strArr.length;
        let arr = [];
        for (let i = 0; i < len - 1; i++) {
            arr.push(strArr[i]);
            arr.push(<br key={i} />);
        }
        // push last element
        arr.push(strArr[len - 1]);

        return arr;
    }

    /** To copy given text to clipboard */
    static copyToClipBoard(txtToCopy) {
        navigator.clipboard.writeText(txtToCopy);
        // alert("Detail Copied !!!");
    }

    /** Call on given number */
    static callOnMobile(contactNumber) {
        window.open('tel:' + contactNumber, '_self');
    }

    /** Mail on given mail-address */
    static mailOnAddress(mailAddress) {
        let mailLinkArr = [
            "mailto:", mailAddress,
            "?subject=", "I would like treatment for DISEASE_NAME"
        ];
        let urlLink = mailLinkArr.join("");
        window.open(urlLink, '_self');
    }

    /**
     * 
     * @param {String} url -URL of page to open
     */
    static openPage(url) {
        window.open(url);
    }

    /**
     * This function will scroll page to given tag name element
     * @param {String} eleTag -Tag name of element to scroll to
     * @param {SyntheticBaseEvent} event  -event args of event generater
     */
    static scrollToView(eleTag, event) {
        let element = document.getElementById(eleTag);
        window.scrollTo({ top: element.offsetTop, behavior: "smooth" });
        event.preventDefault();
    }

    static config = {};

    // Used
    static async fetchJson(fileUrl) {
        return await (await fetch(fileUrl)).json();
    }

    // used
    static async fetchConfig() {
        if (Object.keys(this.config).length === 0) {
            const config = await this.fetchJson(configUrl)
            Object.assign(this.config, config)
        }
        return this.config;
    }

    /**
     * To get list of all/top 6 photos available in treatment result folder
     * @param {boolean=[false]} whole -True: to get whole list, False: to get only top 6's list
     */
    static async getTreatmentResultPhotoList(whole = false) {
        const dataPath = whole ? imgListUrl : top6ImgListUrl;
        const res = await fetch(dataPath);
        return await res.json()
    }
}


export default CommonFunc;