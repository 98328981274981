export const PUBLIC_URL = process.env.PUBLIC_URL

export const iconFolder = "resource/icons/"

export const foreverSymbolUrl = PUBLIC_URL + iconFolder + "ForeverSymbol.svg"

export const hospitalTextSymbolUrl = PUBLIC_URL + iconFolder + "HospitalText.svg"

export const rocketUrl = PUBLIC_URL + iconFolder + "rocket.svg"

export const youtubeIconUrl = PUBLIC_URL + iconFolder + "youtube.svg"

// export const homePhotoUrl = PUBLIC_URL + "resource/home/hospital-front.jpg"

export const pankajNoBackgroundUrl = PUBLIC_URL + "resource/team-members/Pankaj-no-background.svg"

export const hospitalFrontNoBGUrl = PUBLIC_URL + "resource/home/hospital-front-no-bg.png"

export const _24x7TextUrl = PUBLIC_URL + "resource/home/24x7 text.svg"

export const configUrl = PUBLIC_URL + "configs.json"

export const teamMemberImagePath = PUBLIC_URL + "resource/team-members/"

export const imgListUrl = PUBLIC_URL + "resource/treatment-results-list/imgList.json"

export const top6ImgListUrl = PUBLIC_URL + "resource/treatment-results-list/imgListTop6.json"

export const starFilled = PUBLIC_URL + "resource/icons/starFilled.svg";

export const starBlank = PUBLIC_URL + "resource/icons/starBlank.svg";

export const testimonialDataUrl = PUBLIC_URL + "resource/testimonials/data.json"

export const wholeLogoSrc = PUBLIC_URL + "resource/icons/Hospital.svg"

export const mobileIcon = PUBLIC_URL + "/resource/icons/telephone.svg"

export const navbarLinks = [
    {
        name: 'Home',
        tag: 'home'
    },
    {
        name: 'Team',
        tag: 'team'
    },
    {
        name: 'Results',
        tag: 'result'
    },
    {
        name: 'Testimonial',
        tag: 'testimonial'
    },
    {
        name: 'Contact',
        tag: 'contact'
    }
]