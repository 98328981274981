import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './TeamMembersView.scss';
import StartAnchor from './StartAnchor';
import CF from '../libs/commonFunctions';
import { teamMemberImagePath } from '../libs/constants';

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

// Structure of member detail
const memberDetailFormat = {
    memberName: PropTypes.string.isRequired,
    photoName: PropTypes.string.isRequired,
    degree: PropTypes.string.isRequired
}

/**
 * TeamMembersView
 * It is section with all team members view cards
 */
class TeamMembersView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAllMembers: false,
            memberDetails: []
        }

        this.toggle_showAllMembers = this.set_showAllMembers.bind(this, undefined);
    }

    // Props proto-types
    static propTypes = {
        maxShowCount: PropTypes.number
    }

    // Props default value
    static defaultProps = {
        maxShowCount: 5
    }

    async componentDidMount() {
        const config = await CF.fetchConfig()
        this.setState({ memberDetails: config.teamMembers.memberDetails });
    }

    set_showAllMembers(val) {
        // If val is undefined then toggle value
        if (val === undefined) {
            val = !this.state.showAllMembers;
        }
        this.setState({ showAllMembers: val });
    }

    render() {
        return (
            <div className='team-component'>
                <StartAnchor id="team" />
                <h2 className="component-title">Team</h2>
                <Swiper
                    breakpoints={{
                        550: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        1024: {
                            slidesPerView: 3
                        },
                        1230: {
                            slidesPerView: 4
                        },
                        1440: {
                            slidesPerView: 5
                        }
                    }}
                    speed={750}
                    spaceBetween={40}
                    autoplay={
                        {
                            delay: 2000,
                            disableOnInteraction: false,
                        }
                    }
                    pagination={
                        {
                            clickable: true,
                        }
                    }
                    modules={[Pagination, Autoplay, Navigation]}
                    className='member-container'>
                    {
                        this.state.memberDetails.map((e, i) => {
                            return <SwiperSlide key={e.memberName}>
                                <TeamMembersCard memberDetail={e}
                                    style={{ display: ((i >= this.props.maxShowCount) && !this.state.showAllMembers) && 'none' }} />
                            </SwiperSlide>
                        })
                    }
                </Swiper>
                {
                    this.state.memberDetails.length > 5 && (<button className='button' onClick={this.toggle_showAllMembers}>
                        {this.state.showAllMembers ?
                            <>Show Less <span>&larr;</span></> :
                            <>Show More <span>&rarr;</span></>
                        }
                    </button>)
                }

            </div >
        );
    }
}

/**
 * TeamMembersCard
 * It is individual card for team member
 */
class TeamMembersCard extends Component {

    // Props proto-types
    static propTypes = {
        memberDetail: PropTypes.shape(memberDetailFormat)
    }

    render() {
        const { memberDetail } = this.props;
        return (
            <div className='member-card' style={this.props.style}>
                <div className='img-div'>
                    <img
                        className='img'
                        src={teamMemberImagePath + memberDetail.photoName}
                        alt={memberDetail.memberName} />
                </div>
                <p className='name'>{memberDetail.memberName}</p>
                <p className='degree gray-font'>{memberDetail.degree}</p>
            </div>
        );
    }
}


export default TeamMembersView;

/**
 * Make TeamMemberView to scrollable horizontaly al least for mobile
 */