import React, { Component } from 'react';
import { hospitalFrontNoBGUrl, pankajNoBackgroundUrl, _24x7TextUrl } from '../libs/constants';
import './Home.scss';
import StartAnchor from './StartAnchor';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons';

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

import { doRipple } from './animations';
import "./ripple.css";
import CF from '../libs/commonFunctions';

function Slide1() {
    return <div className='home-slide slide-1'>
        <div className="info-block">
            <p className='welcome'>Welcome to</p>
            <h1>Homoeopathy Forever Hospital</h1>

            <h3 className='name'>Dr. Pankaj Lakhani</h3>
            <p className='line'>
                <FontAwesomeIcon icon={faQuoteLeft} className="quote" />
                Spreading awareness about fast and effective results of Homoeopathy.
                <FontAwesomeIcon icon={faQuoteRight} className="quote" />
            </p>

            <button className='slide-1-button blue-button outline-ripple' onClick={e => doRipple(e)}>Know More</button>
        </div>
        <img src={pankajNoBackgroundUrl} alt="dr. Pankaj" className='pankaj-img'></img>
    </div>
}

function Slide2() {
    return <div className="home-slide slide-2" >
        <img src={hospitalFrontNoBGUrl} alt="hospital img" className='hospital-img'></img>
        <span className="visit-container outline-ripple">
            <img src={_24x7TextUrl} alt="24x7" className='_24x7-text'></img>
            <button className='slide-2-button blue-button outline-ripple' onClick={e => { doRipple(e); CF.scrollToView("contact", e) }}>Contact Now</button>
        </span>
    </div >
}

export default class Home extends Component {
    render() {
        return (
            <div className='home-component'>
                <StartAnchor id="home" />
                <Swiper
                    spaceBetween={0}
                    autoplay={
                        {
                            delay: 5000,
                            disableOnInteraction: false,
                        }
                    }
                    pagination={
                        {
                            clickable: true,
                        }
                    }
                    modules={[Pagination, Autoplay, Navigation]}>
                    <SwiperSlide><Slide1 /></SwiperSlide>
                    <SwiperSlide><Slide2 /></SwiperSlide>
                </Swiper>
            </div>
        )
    }
}
