import React from 'react';
import './Footer.scss';
import ContactView from './ContactView';
import CF from '../libs/commonFunctions';
import StartAnchor from './StartAnchor';
import { wholeLogoSrc, navbarLinks } from '../libs/constants';

export default function Footer() {
    const navLinks = navbarLinks.map(
        ele => <button className="gray-font" key={ele.tag} onClick={CF.scrollToView.bind(this, ele.tag)}>
            {ele.name}
        </button>
    )

    return (
        <div className='footer-component'>
            <div className='navbar'>
                <div className='site-logo'>
                    <img src={wholeLogoSrc} alt="HFH hospital logo" className='logo'></img>
                </div>
                <div className='nav-links'>
                    {navLinks}
                </div>
            </div>

            <div className='contact-component'>
                <StartAnchor id="contact" />
                <ContactView />
            </div>
        </div>
    )
}
