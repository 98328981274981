import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import PropTypes from "prop-types";
import './TreatmentResultView.scss';
import StartAnchor from './StartAnchor';
import CF from '../libs/commonFunctions';
import { PUBLIC_URL } from '../libs/constants';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';


/**
 * It will have show more option which will redirect to all result page
 * through react router dom
 */
class TreatmentResultView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            totalImages: 0,
            images: []
        }

    }

    async componentDidMount() {
        const imgList = await CF.getTreatmentResultPhotoList(false);
        const imgSrcList = imgList.map(imgName => PUBLIC_URL + "resource/treatment-results/" + imgName)
        this.setState({ images: [...imgSrcList] })
    }

    static propTypes = {
        maxShowCount: PropTypes.number
    };
    static defaultProps = {
        maxShowCount: 5
    };

    render() {
        const resultCards = this.state.images.map(
            (imgSrc, index) => <ResultCard src={imgSrc} key={index} />
        )

        return (
            <div className='treatment-result-component'>
                <StartAnchor id="result"></StartAnchor>
                <h2 className="component-title">Results</h2>
                <div className="result-container">{resultCards}</div>

                <Link to='/result-Gallery' target='_blank' className='more-button blue-button outline-ripple'>
                    {/* <button className='more-button blue-button outline-ripple'> */}
                    More&nbsp;<FontAwesomeIcon icon={faArrowRight} />
                    {/* </button> */}
                </Link>
            </div >
        );
    }
}


class ResultCard extends Component {
    openImageInNewTab(src) {
        window.open(src, '_black');
    }

    render() {
        const src = this.props.src
        if (src) {

            let temp = src.split("/")
            const alt = temp[temp.length - 1].split(".")[0]

            return (
                <div className='result-card'>
                    <img
                        className="result-image"
                        alt={alt}
                        src={src}
                        onClick={this.openImageInNewTab.bind(this, src)}
                    />
                </div>
            );
        } else {
            return null;
        }
    }

}

export default TreatmentResultView;

export { ResultCard };

// TODO: Change style of images