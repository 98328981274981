import React, { Component } from 'react';
import CF from '../libs/commonFunctions';
import { foreverSymbolUrl, hospitalTextSymbolUrl, navbarLinks } from '../libs/constants';
import './SiteNavbar.scss';


/**
 * Combination of icon and name for navbar
 */
function NavbarLogo() {
  return (
    <div className='site-signature'>
      <div className='site-logo-div'>
        <img src={foreverSymbolUrl} alt='site logo' className='site-logo'></img>
      </div>
      <div className='logo-text'>
        <img src={hospitalTextSymbolUrl} alt='site name' className='site-logo-text'></img>
      </div>
    </div>
  )
}
/**
 * Site navbar class component
 * it will be added as first child of main container element
 * prop of type [{name:string, href:string}] is required
 * which are to be showed as quick links
 */
class SiteNavbar extends Component {
  constructor(props) {
    super(props);
    /**
     * showNavItems: will be used in mobile view. need to show navitems or not
     */
    this.state = {
      showNavItems: false,
      navbarLinks: []
    };

    this.showNavItems_toggle = this.set_showNavItems.bind(this, undefined);
    this.showNavItems_false = this.set_showNavItems.bind(this, false);
    this.showNavItems_true = this.set_showNavItems.bind(this, true);
  }

  set_showNavItems(val) {
    if (val === undefined) {
      val = !this.state.showNavItems;
    }
    this.setState({ showNavItems: val });
  }

  render() {

    return (
      <div className='nav-container'>
        <NavbarLogo />

        {
          (!this.props.sectionName) ?
            (
              <nav className='navbar'>
                {
                  navbarLinks.map((ele, i) => {
                    return (
                      <button
                        href='#'
                        key={i}
                        className={`navbar-item ${!this.state.showNavItems ? "hide-navbar" : ""} `}
                        onClick={(event) => { CF.scrollToView(ele.tag, event); this.showNavItems_false(); }}
                      >{ele.name}</button>
                    );
                  })
                }
                <div className='mobile-navigator' onClick={this.showNavItems_toggle}>
                  <div className={`navigator-arrow ${this.state.showNavItems && "inverse-arrow"}`}></div>
                </div>
              </nav>
            ) :
            (
              <div className='section-name'><span>{this.props.sectionName}</span></div>
            )
        }
      </div>
    );
  }
}

export default SiteNavbar;
