import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CF from '../libs/commonFunctions';
// TODO remove comment to use individual component
import './ContactView.scss';
import parse from 'html-react-parser';
import CustomIcon from '../customIcons';
import { bmClock, bmLocation, bmMail, bmMobileCalling } from '../customIcons/solidIcons';

// contact detail format
const contactDetail_format = {
    branchName: PropTypes.string.isRequired,
    branchContactNo: PropTypes.string.isRequired,
    branchMail: PropTypes.string.isRequired,
    branchAddress: PropTypes.string.isRequired,
    branchLocation: PropTypes.string.isRequired,
    branchTiming: PropTypes.string.isRequired
}

// Whole contact view
class ContactView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            contactDetails: {}
        }
    }
    async componentDidMount() {
        const config = await CF.fetchConfig();
        let new_contactDetails = this.state.contactDetails;
        Object.assign(new_contactDetails, config.contactDetails);
        this.setState({ contactDetails: new_contactDetails })
    }

    render() {
        return (
            <div className='contact-view-component'>
                <h2 className="component-title gray-font">Contact</h2>
                <div className="contact-container">
                    {Object.keys(this.state.contactDetails).length ? <ContactCard contactDetail={this.state.contactDetails} /> : ''}
                </div>
            </div>
        );
    }
}

const cursorStyles = {
    cursor: `url("${process.env.PUBLIC_URL}resource/icons/copyCursor.svg"), pointer`
}

// Individual Branch's contact card
class ContactCard extends Component {

    static propTypes = {
        contactDetail: PropTypes.shape(contactDetail_format)
    }

    copyText(e) {
        CF.copyToClipBoard(e.target.innerText)
    }

    render() {
        const contactDetail = this.props.contactDetail;
        const {
            branchName,
            branchContactNo,
            branchMail,
            branchLocation,
            branchAddress,
            branchTiming } = contactDetail

        return (
            <div className='contact-card'>
                <p className='branch-name'>{branchName}</p>
                <div className='details'>
                    <div className='detail-box mobile' >
                        <div className='icon-div'
                            onClick={CF.callOnMobile.bind(this, branchContactNo)}>
                            <CustomIcon icon={bmMobileCalling} className="icon" />
                        </div>
                        <div
                            className='text gray-font'
                            style={cursorStyles}
                            onClick={this.copyText.bind(this)}
                        >
                            {branchContactNo}
                        </div>
                    </div>

                    <div className='detail-box mail'>
                        <div className='icon-div'
                            onClick={CF.mailOnAddress.bind(this, branchMail)}>
                            <CustomIcon icon={bmMail} className="icon" />
                        </div>
                        <div
                            className='text gray-font'
                            style={cursorStyles}
                            onClick={this.copyText.bind(this)}>
                            {parse(branchMail)}
                        </div>
                    </div>

                    <div className='detail-box address' onClick={() => { window.open(branchLocation) }}>
                        <div className='icon-div'>
                            <CustomIcon icon={bmLocation} className="icon" />
                        </div>
                        <div className='text gray-font'>{CF.get_MultiLineText(branchAddress)}</div>
                    </div>

                    <div className="detail-box timing">
                        <div className='icon-div'>
                            <CustomIcon icon={bmClock} className="icon" />
                        </div>
                        <div className='text gray-font'>{CF.get_MultiLineText(branchTiming)}</div>
                    </div>
                </div>
            </div>
        );

    }
}

export default ContactView;