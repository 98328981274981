import React, { Component } from 'react';
import './WhySection.scss';
import CF from '../libs/commonFunctions';
import parse from 'html-react-parser';
import { rocketUrl, youtubeIconUrl } from '../libs/constants';
import { InView } from 'react-intersection-observer';

export default class WhySection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      config: null,
      inView: false
    }
    this.scrollListener = null
  }

  async componentDidMount() {
    this.setState({ config: await CF.fetchConfig() });
  }

  setInview(val) {
    this.setState({ inView: val })
  }

  render() {
    const { config, inView } = this.state;
    let rocketClasses = (inView ? "rocket-animate " : "") + "rocket-icon";

    return (
      config !== null &&
      <div className="why-component">

        <h2 className='why'>Why Homoeopathy?</h2>

        <div className='answer-container gray-font'>
          <p className='al1'>Simple | Fast | Effective</p>
          <p className='al2'>
            {parse(config.line1)}<span className="hidden-line-for-google" style={{ display: "none" }}> Visit today for treatment</span>
          </p>
          <p className="al3">
            {parse(config.line2)}
          </p>
          {!this.state.inView && <InView onChange={this.setInview.bind(this)} />}
          <p className='al4'>
            <a
              href={config.youtubeLink}
              target='_blank'
              rel="noreferrer"
            >
              <img src={youtubeIconUrl} alt='youtube' className='youtube-icon outline-ripple'></img>
            </a>
            {config.line3} <a
              className='yt-link'
              href={config.youtubeLink}
              target='_blank'
              rel="noreferrer"
            >{parse(config.youtubeChannelName)}</a>
          </p>
        </div>
        <img src={rocketUrl} alt='rocket' className={rocketClasses}></img>
      </div>
    )
  }
}
