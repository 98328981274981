import React from "react"
import "./CustomIcon.css"

export default function CustomIcon(props) {
    const { icon, className = "", ...restProps } = props;
    const { iconName, viewBox, path } = icon;

    return (
        <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="bm"
            data-icon={iconName}
            className={`bm-icon ${className}`}
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox={viewBox}
            {...restProps}
        >
            <path
                // className={className}
                fill="currentColor"
                d={path}
            />
        </svg>
    )
}