import { BrowserRouter, Route, Routes } from "react-router-dom";

import './App.scss';

import SiteNavbar from './SiteNavbar';
import TeamMembersView from './TeamMembersView';
import TreatmentResultView from './TreatmentResultView';
import Testimonials from './Testimonials';
import WhySection from './WhySection';
import Home from './Home';
import Footer from './Footer';
import ResultGallery from "./ResultGallery";


function MainAppWraper() {
    return (
        <div className="App">
            <SiteNavbar />

            <Home />
            <WhySection />
            <TeamMembersView />
            <TreatmentResultView />
            <Testimonials />

            <Footer />
        </div >
    );
}

function ResultGalleryWrapper() {
    return (
        <div className="Gallery">
            <SiteNavbar sectionName="Result Gallery" />
            <ResultGallery />
        </div>
    )
}


function App() {
    return (
        <BrowserRouter>
            <h1 style={{
                height: "0px",
                color: "transparent"
            }}>Homoeopathy Forever Hospital</h1>
            <Routes>
                <Route exact path='/' element={<MainAppWraper />}></Route>
                <Route exact path='/result-Gallery' element={<ResultGalleryWrapper />}></Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
