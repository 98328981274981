import React, { Component } from 'react';
import CF from '../libs/commonFunctions';
import { PUBLIC_URL } from '../libs/constants';
import './ResultGallery.scss';
import { ResultCard } from './TreatmentResultView';

function needToLoad() {
    const totalPageHeight = document.body.offsetHeight;
    const scrolledHeight = window.scrollY;
    const viewableHeight = window.innerHeight;

    const totalViewedHight = scrolledHeight + viewableHeight
    const threshold = totalPageHeight - 200;

    // Load when we have seen upto some threshold OR when screen is not fully filled
    return totalViewedHight >= threshold || viewableHeight === totalPageHeight
}

export default class ResultGallery extends Component {

    constructor(props) {
        super(props);
        this.state = {
            images: []
        }
        this.allImageNames = []; // it will be only names 
        this.isWatcher = false; // see if watcher is already there
    }

    async componentDidMount() {
        const imgList = await CF.getTreatmentResultPhotoList(true);
        this.allImageNames = imgList;
        this.putWatcher()
        document.addEventListener("scroll", this.scrollListener)
    }

    allLoaded() {
        return this.state.images.length === this.allImageNames.length;
    }

    putWatcher() {
        // console.log(!this.isWatcher, !this.allLoaded(), needToLoad())
        if (!this.isWatcher && !this.allLoaded() && needToLoad()) {
            this.loadNext()
        }
    }

    loadNext() {
        this.setState(
            (state) => ({
                images: [...state.images, createImgUrl(this.allImageNames[state.images.length])]
            }), () => this.isWatcher = false
        )
    }

    componentDidUpdate() {
        this.putWatcher()
    }

    scrollListener = () => {
        this.putWatcher()
    }

    componentWillUnmount() {
        document.removeEventListener("scroll", this.scrollListener);
    }

    render() {
        const { images } = this.state

        return (
            <div className='result-gallery-component'>
                <div className="image-container">
                    {
                        images.map(
                            (imgSrc, index) =>
                                <ResultCard src={imgSrc} key={index} index={index} />
                        )
                    }
                </div>
            </div>
        )
    }
}

function createImgUrl(imgName) {
    return `${PUBLIC_URL}resource/treatment-results/${imgName}`;
}