import React from 'react';
import './StartAnchor.scss';



/**
 * Tag to compansate fixed navebar on top of site
 * add this tag at starting of each component
 * @returns JSX.Element
 */
export default function StartAnchor(props) {
    return (
        <p className='head-anchor' id={props.id}>This is Navbar Compansation</p>
    )
}
